<template>
  <b-card title="Schema">
    <b-tabs>
       <b-tab active  @click="$router.push('/statistics-schema')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Statistics Schema</span>
        </template>
        <SchemaStatistics/>
      </b-tab>
      <b-tab   @click="$router.push('/all-schema')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span data-testid="schema-course-tab">Add/Update Schema Course</span>
        </template>
        <SchemaCourse/>
      </b-tab>
       <b-tab @click="$router.push('/add-schema-post')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span data-testid="schema-post-tab"> Add Post</span>
        </template>
        <PostSchema />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
       <b-tab @click="$router.push('/add-schema-event')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span data-testid="schema-event-tab"> Add Event</span>
        </template>
        <SchemaEvent />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
 <b-tab @click="$router.push('/faq-schema-index')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span data-testid="schema-faq-tab"> FAQ</span>
        </template>
        <FaqSchema />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
 
      
      <b-tab @click="$router.push('/all-schema')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span data-testid="schema-all-tab" >All Schema</span>
        </template>
        <AllSchema />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
      
    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import SchemaCourse from './SchemaCourse.vue'
import PostSchema from './PostSchema.vue'
import SchemaEvent from './SchemaEvent.vue'
import AllSchema from './allSchema.vue'
import FaqSchema from './FaqSchema.vue'
import SchemaStatistics from './SchemaStatistics.vue'


export default {
  components: {
    BCard,
    BTabs,
    BTab,
    PostSchema,
 
   
    SchemaEvent,
   
    SchemaStatistics,
    AllSchema,
    FaqSchema,
    SchemaCourse,
  },
  data() {
    return {

    }
  },
}
</script>
